import styled, {css} from 'styled-components'
import { rem } from '../../utils/common'
import { device } from '../Variable'
import backIcon from '../../assets/icons/back-icon.png'

export const LanguageSelectStyle = styled.div`
	display: block;
	max-width: ${rem(160)};
	width: 100%;
	
	// vertical line on the left side of the lang select IDK for what	
	// &:before {
    //       position: absolute;
    //       content: '';
    //       width: ${rem(1)};
    //       height: ${rem(22)};
    //       background: #d1cfcd;
    //       left: ${rem(-18)};
    //       top: 50%;
    //       margin-top: ${rem(-11)};
	// }	
    
    ${(p) => p.isMarketSwitcherDisabled && css`
        &:before {
          display: none;
        }
    `}
    
    ${(p) => p.isTokenLogin && css`
        &:before {
          display: none;
        }
    `}
	
	@media (${device.mobileL}) {
	    position: relative;
	    max-width: ${rem(149)};
		width: 100%;
		z-index: 6;
		
		&:before {
		  display: none;
		}
	}
	.react-select {
		&__control {
			width: 100%;
			max-height: ${rem(32)};
			border-radius: ${rem(5)};
			@media (${device.mobileL}) {
				width: 100%;
				margin: 0 auto;
			}
		}
		&__indicators {
			cursor: pointer;
			height: 100%;
		}
		&__indicator-separator {
			display: none;
		}
		&__dropdown-indicator {
		}
		&__value-container {
		  height: 100%;
		}
		&__single-value {
			@media (${device.mobileL}) {
				font-size: ${rem(14)};
			}
		}

		&__menu {
		  margin: ${rem(1, 0,0,0)};
		  border-radius: 0;
		  min-width: ${rem(140)};
		  
		  .market-change-switcher {
		    text-align: center;
		    cursor: pointer;
		    margin: ${rem(0, 5)};
		    padding: ${rem(4, 0)} ;
		    border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.graySix};
		    color: ${({ theme }) => theme.color.graySix};
		    font-size: ${rem(14)};
		    position: relative;
		    
		    &:before {
		      position: absolute;
		      content: '';
		      left: ${rem(5)};
		      height: ${rem(16)};
		      width: ${rem(16)};
		      top: 50%;
		      margin-top: ${rem(-8)};
		      border-radius: 50%;
		      background-color: #e3e3e3;
		      background-image: url(${backIcon});
		      background-repeat: no-repeat;
		      background-position: ${rem(4)} ${rem(4)};
		    }
		    
		    &:hover {
		      color: ${({ theme }) => theme.color.grayThree};
		      border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.grayThree};
		      &:before {
                  box-shadow: ${rem(0)} 0 ${rem(1)} ${({ theme }) => theme.color.grayThree};
                }
		    }
		  }
		}

	}	
`