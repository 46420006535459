import styled, {css} from 'styled-components'
import {rem} from '../../utils/common'
import {device, fonts} from '../../components/Variable'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: ${rem(70)};
  background-color: ${({theme}) => theme.color.secondary};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

export const MainBackground = styled.div`
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  background: url("https://returnado-static.s3.eu-west-1.amazonaws.com/hunkemoller/back-1.jpg") #ffffff no-repeat center/cover;
  
  .scrollbar-container {
    padding: 0 0 ${100} 0;
  }
  
  ${p => p.isOpen && css`
    background-position: center bottom ${rem(-200)}, center;
  `}
`
export const MainContent = styled.div`
  max-width: ${rem(589)};
  padding-bottom: ${rem(20)};
  margin: 0 auto;  
  @media (${device.tablet}) {
    width: 90%;
    margin-top: 0;
  }
`
export const MainContentLanguageWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: ${rem(75)};
  padding: ${rem(16)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;   
  @media (${device.tablet}) {
    top: ${rem(90)};
    left: ${rem(14)};
  }
  @media (${device.mobileL}) {
    justify-content: space-between;
    top: ${rem(90)};
    left: ${rem(5)};
    
    > div:first-child {
      margin: 0;
    }
    > div {
      width: 50%;
    }
  }
`

export const MainLogoImg = styled.img`
  display: flex;
  justify-content: center;
  position: fixed;
  margin: ${rem(20)} auto ${rem(130)} auto;
  width: 100%;
  max-width: ${rem(200)};
  transition: all .2s;
  opacity: 1;
  z-index: 3;  
  @media (${device.mobileL}) {
    width: ${rem(240)};    
  }

  ${p => p.isOpen && css`
    opacity: 0;
  `}
`

export const MainLogoImgWrapper = styled.div`
  display: block;
	max-width: ${rem(160)};
	width: 100%;
	position: relative;
  @media (${device.tablet}) {
    width: 90%;
    margin: ${rem(25)} auto ${rem(10)} auto;
  }
  
  ${p => p.isOpen && css`
    opacity: 0;
  `}
  
  ${p => p.noImage && css`
    min-height: ${rem(53)};
    visibility: hidden;
        svg {
          display: none;
        }
  `}
`
export const Title = styled.div`
    text-align: center;
    cursor: pointer;
    position: relative;
    font-family: ${fonts.Nunito};
    color: ${({theme}) => theme.color.blackTwo};
    font-size: ${rem(16)};
    margin-bottom: ${rem(15)};
    transition: margin-bottom 0.5s;

  @media (${device.tablet}) {
    font-size: ${rem(15)};
    ${p => !p.isOpen && css`
      margin-bottom: ${rem(0)};
    `}
  }

  svg {
    width: 100%;
  
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}
  }
`

export const ArrowControlWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    transition: all .3s;
    transform: rotate(0deg);
    width: ${rem(24)};
    height: ${rem(24)};
    padding: ${rem(5)};
    z-index: 10;
    
    @media (${device.tablet}) {
      width: ${rem(20)};
      height: ${rem(20)};
      right: ${rem(0)};
      top: 0;
    }
    
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}    
`

export const MainText = styled.div`
  background-color: ${({theme}) => theme.color.secondary};
  padding: ${rem(15, 32, 10, 32)};
  width: 100%;
  margin-top: ${rem(20)};
  position: relative;
  z-index: 3;
  max-height: ${rem(58)};
  overflow: hidden;
  transition: all .3s;
  box-shadow: none;
  border-radius: ${rem(5)}; 
  @media (${device.tablet}) {
    max-height: ${rem(50)};
    padding: ${rem(10, 10, 10, 10)};
  }

  ${p => p.isOpen && css`
    max-height: 200vh !important;
  `}
`
export const BoxAnimation = styled.div`
  position: relative;
  margin-bottom: ${rem(60)};  
  @media (${device.tablet}) {
    margin-bottom: ${rem(60)};
  }
`
export const AccordionWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
  max-height: 0;
  
  ${p => p.isOpen && css`
    max-height: 400vh;
  `}  
`

export const EmptyLogoWrapper = styled.div`
  height: ${rem(120)};
  @media (${device.tablet}) {
    height: ${rem(35)};
  }
`
