import React from 'react';
import {useSelector} from "react-redux";
import {MainLogoImg, MainLogoImgWrapper, EmptyLogoWrapper, HeaderContainer} from "../../containers/Main/style";
import {Icon} from "../Icon";
import humkemollerLogo from '../../assets/hunkemoller_logo.svg';
import {merchantThemes} from "../Variable";

const MerchantLogo = ({ isOpen }) => {
    const { currentTheme, shopProperties, locale } = useSelector(({root}) => ({
        currentTheme: root.currentTheme,
        shopProperties: root.shopProperties,
        locale: root.locale
    }));
    const themeName = currentTheme && currentTheme.name ? currentTheme.name : '';
    /**
     * getLogoHref {Function}
     * @description - get current logo href based on locale and shop settings
     * @return {String}
     *
     * */
    const getLogoHref = (locale) => {
        let urls = shopProperties['widget.logo.urls'] || null;
        let urlArr = urls ? urls.split(',') : null;
        let hrefsMap = {}

        if(urlArr) {
            urlArr.forEach((hrefItem)=>{
                let currentUrlArr = hrefItem.split('_');
                hrefsMap[currentUrlArr[0]] = currentUrlArr[1]
            })
        }

        return hrefsMap[locale] ? hrefsMap[locale] : null
    };
    let logoUrl = getLogoHref(locale)
    let logotypes = {
        [merchantThemes.hm] : humkemollerLogo,
    }

    let getLogo = (theme) => {
        let currentTheme = logotypes[theme];
        switch (logotypes[theme]) {
            case 'empty':
                return <EmptyLogoWrapper/>;
            case 'icon':
                return <MainLogoImgWrapper isOpen={isOpen} themeName={themeName} noImage>
                    <Icon component={'woodWoodLogo'} />
                </MainLogoImgWrapper>;
            default:
                return logoUrl ? <a href={logoUrl} target='_blank' rel="noreferrer">
                    <MainLogoImg isOpen={isOpen} src={currentTheme} />
                </a> : <MainLogoImg isOpen={isOpen} src={currentTheme} />
        }
    }

    return (
        <HeaderContainer>
            { getLogo(themeName)}
        </HeaderContainer>
    )
}
export default MerchantLogo