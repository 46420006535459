import styled from 'styled-components'
import {device} from '../../../../components/Variable'
import {rem} from "../../../../utils/common";

export const FooterWrapper = styled.footer`
    background-color: ${({theme}) => theme.color.footerBg};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
`

export const FooterLogoWrapper = styled.div`
    padding: ${rem(10)} ${rem(0)} ${rem(10)};
    max-width: ${rem(150)};
    margin: 0 auto;
    
    svg {
      width: 100%;
      fill: ${({theme}) => theme.color.headerLogo}
    }
    
    @media (${device.tablet}) {
      padding: ${rem(5)} ${rem(0)};
      max-width: ${rem(100)};
	}
	
`

export const FooterLink = styled.a`
    text-decoration: none;
    & svg {
      fill: ${({theme}) => theme.color.headerLogo}
    }
    &:hover {
      text-decoration: underline;
    }
`