import React, {} from 'react'
import {FooterWrapper, FooterLogoWrapper, FooterLink} from "./style";
import {Icon} from "../../../../components/Icon";

const Footer = () => {
    let renderFooterLogo = () => {
        return  <FooterLogoWrapper>
                    <FooterLink href="https://nshift.com/" target="_blank">
                        <Icon component={'nShiftLogo'}/>
                    </FooterLink>
                </FooterLogoWrapper>
        }

    return (
        <FooterWrapper>
            { renderFooterLogo() }        
        </FooterWrapper>
    )
}
export default Footer
