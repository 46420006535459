import React, { useCallback } from 'react'
import {merchantThemes} from "../components/Variable";

/**
 * rem {convertedPxToRem}
 * @description - converted Px to Rem;
 *
 * */
export const rem = (...props) => props.map((prop) => +prop / 16 + 'rem ')

/**
 * parseJSON
 * @description - checks JSON for errors;
 *
 * */
export const parseJSON = (data) => {
	let value = false
	try {
		value = JSON.parse(data)
	} catch (err) {}
	return value
}

/**
 * getUrlParameter {createTemporaryLinkElement}
 * @description - get Url parameters by name;
 *
 * */
export function getUrlParameter(name) {
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
	let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
	let results = regex.exec(window.location.search)
	return results === null
		? ''
		: decodeURIComponent(results[1].replace(/\+/g, ' '))
}

/**
 * langList {Function}
 * @description - convert option Language from server to array from React Select
 *
 * */
export function langList(string, isTitle=false) {
	const options = [
		{ value: 'en', label: 'English' },
		{ value: 'sv', label: 'Svenska' },
		{ value: 'fr', label: 'Français' },
		{ value: 'es', label: 'Español' },
		{ value: 'it', label: 'Italiano' },
		{ value: 'de', label: 'Deutsch' },
		{ value: 'no', label: 'Norsk' },
		{ value: 'da', label: 'Dansk' },
		{ value: 'fi', label: 'Suomalainen' },
		{ value: 'nl', label: 'Dutch' },
		{ value: 'pl', label: 'Polish' },
		{ value: 'ko', label: '한국인' },
		{ value: 'jp', label: '日本語' },
	]
	const titles = [
		{ value: 'en', label: 'EN' },
		{ value: 'sv', label: 'SV' },
		{ value: 'fr', label: 'FR' },
		{ value: 'es', label: 'ES' },
		{ value: 'it', label: 'IT' },
		{ value: 'de', label: 'DE' },
		{ value: 'no', label: 'NO' },
		{ value: 'da', label: 'DA' },
		{ value: 'fi', label: 'FI' },
		{ value: 'nl', label: 'NL' },
		{ value: 'pl', label: 'PL' },
		{ value: 'ko', label: 'KO' },
		{ value: 'jp', label: 'JP' },
	]
	let optionsArr = isTitle ? titles : options;
	let filteredOptions = []

	//en,sv,de,no,da,fi
	optionsArr.forEach((lang) => {
		if(string?.includes(lang.value)) {
			filteredOptions.push(lang)
		}
	})

	return filteredOptions;
}

/**
 * getFormattedShopCodes {Function}
 * @description - get formatted shop codes
 *
 * */
export function getFormattedShopCodes(codes) {
	let formattedShopCodes = [];

	for (let shopCodesKey in codes) {
		if(codes.hasOwnProperty(shopCodesKey)) {
			let labelArr = codes && codes[shopCodesKey] && codes[shopCodesKey].marketName ? codes[shopCodesKey].marketName.split(" - ") : [];
			let marketValueId = codes[shopCodesKey].marketId;
			const marketLabel = labelArr && labelArr[0] ? labelArr[0] : codes[shopCodesKey].marketName;
			if(parseInt(marketValueId) % 1000 !== 0) {
				formattedShopCodes.push({
					value: codes[shopCodesKey].marketId,
					label: marketLabel,
					...codes[shopCodesKey]
				})
			}
		}
	}

	return formattedShopCodes;
}

/**
 * useYupValidationResolver{validationSchema}
 * @description - The custom hook, by passing the validation schema;
 *
 * */
export const useYupValidationResolver = (validationSchema) =>
	useCallback(
		async (data) => {
			try {
				const values = await validationSchema.validate(data, {
					abortEarly: false,
				})

				return {
					values,
					errors: {},
				}
			} catch (errors) {
				return {
					values: {},
					errors: errors.inner.reduce(
						(allErrors, currentError) => ({
							...allErrors,
							[currentError.path]: {
								type: currentError.type ?? 'validation',
								message: currentError.message,
							},
						}),
						{}
					),
				}
			}
		},
		[validationSchema]
	)
/**
 * SS {Function}
 * @description - CRUD by SessionStorage
 * */
export const SS = (function () {
	function get(key) {
		return parseJSON(sessionStorage.getItem(key))
	}
	function set(key, val) {
		sessionStorage.setItem(key, JSON.stringify(val))
	}
	function remove(key) {
		sessionStorage.removeItem(key)
	}
	function clear() {
		sessionStorage.clear()
	}
	return {
		get,
		set,
		remove,
		clear,
	}
})()


/**
 * stopPropagation {Function}
 * @description - stop event on inside element click
 *
 * */
export function stopPropagation(e) {
	e.stopPropagation()
	e.nativeEvent.stopImmediatePropagation()
}
/**
 * overflowBodyAdd {Function}
 * @description - add class
 *
 * */
export function overflowBodyAdd() {
	const target = document.getElementById('root')
	target.classList.add('overflow-hidden')
}
/**
 * overflowBodyRemove {Function}
 * @description - remove class
 *
 * */
export function overflowBodyRemove() {
	const target = document.getElementById('root')
	target.classList.remove('overflow-hidden')
}
/**
 * getCurrentTheme {Function}
 * @description - get current theme
 *
 * */
export function getCurrentTheme() {
	let shopProperties = SS.get('shopProperties') || null
	return shopProperties && shopProperties['ui.theme'] ? shopProperties['ui.theme'].toLowerCase() : ''
}

/**
 * removeUrlParameter {Function}
 * @description - remove specific parameter from url
 * @param {Object}
 * @param {Object}
 * @param {String}
 *
 * */
export function removeUrlParameter(location, history, paramName) {
	try {
		const queryParams = new URLSearchParams(location.search)
		if (queryParams.has(paramName)) {
			queryParams.delete(paramName)
			history.replace({
				search: queryParams.toString(),
			})
		}
	} catch (e) {
		console.error(e, `can't remove url parameter ${paramName} from url`)
	}
}

/**
 * getFirstMerchantCode {Function}
 * @description - get first merchant code
 *
 * */
export function getFirstMerchantCode(codes) {
	let currentMerchantCode = null;
	for (let key in codes) {
		if (codes.hasOwnProperty(key) && !currentMerchantCode) {
			currentMerchantCode = key;
		}
	}
	return currentMerchantCode
}

/**
 * getFirstMerchantCode {Function}
 * @description - get first merchant code
 *
 * */
export function getCurrentShopCode(codes, merchantCode) {
	return codes ? codes[merchantCode] ? codes[merchantCode] : {} : {}
}

/**
 * getFilteredShopCodes {Function}
 * @description - get filtered shop codes by theme name
 *
 * */

export function getFilteredShopCodes(codes, theme) {
	let filteredCodes = {}

	for(let key in codes) {
		if(codes.hasOwnProperty(key)) {
			let currentMarketItem = codes[key]
			switch (theme) {
				case merchantThemes.royal_design:
					if(currentMarketItem.marketName && currentMarketItem.marketName.includes(' - RD')) {
						filteredCodes[key] = currentMarketItem
					}
					break;
				case merchantThemes.rum21:
					if(currentMarketItem.marketName && currentMarketItem.marketName.includes(' - Rum21')) {
						filteredCodes[key] = currentMarketItem
					}
					break;
				case merchantThemes.hh:
					if(currentMarketItem.marketName && currentMarketItem.marketName.includes('SW-')) {
						filteredCodes[key] = currentMarketItem
					}
					break;
				case merchantThemes.hh_ww:
					if(currentMarketItem.marketName && currentMarketItem.marketName.includes('WW-')) {
						filteredCodes[key] = currentMarketItem
					}
					break;
				default:
					filteredCodes[key] = currentMarketItem
					break;				
			}
		}
	}

	return filteredCodes;
}

/**
 * getAlphabeticallySortedList {Function}
 * @description - get alphabetically sorted list (array of objects) by Market/Language ('label' property)
 *
 * */
export function getAlphabeticallySortedList(arrOfObj) {
	return arrOfObj.sort((a, b) => a.label > b.label ? 1 : -1)
}
