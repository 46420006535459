import React from 'react'

// Block Icons ...
export const minusRadial = (props) => (
    <svg
        {...props}
        width='24'
        height='24'
        fill='#43484D'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 0C5.37282 0 0 5.37282 0 12C0 18.6272 5.37282 24 12 24C18.6272 24 24 18.6272 24 12C24 5.37282 18.6272 0 12 0ZM12 2.24427C17.3877 2.24427 21.7557 6.6123 21.7557 12C21.7557 17.3877 17.3877 21.7557 12 21.7557C6.6123 21.7557 2.24427 17.3877 2.24427 12C2.24427 6.6123 6.6123 2.24427 12 2.24427ZM6.41989 12.2863C6.41989 12.9186 6.93254 13.4313 7.56492 13.4313H16.7252L16.8247 13.428C18.2512 13.3306 18.218 11.1412 16.7252 11.1412H7.56492L7.47947 11.1444C6.88699 11.1881 6.41989 11.6826 6.41989 12.2863Z'
        />
    </svg>
)

export const arrowsDown = (props) => (
<svg
    {...props}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="15px"
    viewBox="0 0 50 20"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M35 360 c-5 -8 32 -52 104 -124 l111 -111 111 111 c72 72 109 116
104 124 -5 8 -41 -22 -111 -91 l-104 -104 -104 104 c-70 69 -106 99 -111 91z"/>
</g>
</svg>

)

export const arrowsUp = (props) => (
    <svg
        {...props}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="15px"
        viewBox="0 0 48 20"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
            <path d="M136 261 c-69 -70 -106 -113 -101 -121 5 -8 42 22 111 91 l104 104
104 -104 c69 -69 106 -99 111 -91 5 8 -32 51 -101 121 -60 60 -111 109 -114
109 -3 0 -54 -49 -114 -109z"/>
        </g>
    </svg>


)

export const plusRadial = (props) => (
    <svg
        {...props}
        width='24'
        height='24'
        fill='#43484D'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 12C0 5.37282 5.37282 0 12 0C18.6272 0 24 5.37282 24 12C24 18.6272 18.6272 24 12 24C5.37282 24 0 18.6272 0 12ZM11 7.41985C11 5.92706 13.1895 5.89388 13.2868 7.32033L13.2899 7.41985L13.29 11.1412H16.7252C18.218 11.1412 18.2512 13.3306 16.8247 13.428L16.7252 13.4313H13.29L13.2901 16.8664C13.2901 17.4701 12.823 17.9646 12.2305 18.0083L12.1451 18.0114C11.5414 18.0114 11.0469 17.5443 11.0032 16.9519L11 16.8664V13.4313H7.56492C6.93254 13.4313 6.41989 12.9186 6.41989 12.2863C6.41989 11.6826 6.88699 11.1881 7.47947 11.1444L7.56492 11.1412H11V7.41985Z'
        />
    </svg>
)
export const arrowDown = (props) => (
    <svg
        {...props}
        width='16'
        height='10'
        viewBox='0 0 16 10'
        fill='#848499'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M13.8989 0.390524C14.3796 -0.130176 15.1589 -0.130176 15.6395 0.390524C16.1202 0.911223 16.1202 1.75544 15.6395 2.27614L8.87029 9.60948C8.38964 10.1302 7.61036 10.1302 7.12971 9.60948L0.360484 2.27614C-0.120161 1.75544 -0.120161 0.911223 0.360484 0.390524C0.841129 -0.130176 1.62041 -0.130176 2.10105 0.390524L8 6.78105L13.8989 0.390524Z'/>
    </svg>
)
export const returnIcon = (props) => (
    <svg
        {...props}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM9.02904 6.62858C9.32643 6.93677 9.32335 7.43192 9.02676 7.73929L8.18105 8.61574H17.688C18.1059 8.61574 18.4444 8.96662 18.4444 9.39946C18.4444 9.83531 18.1058 10.1832 17.688 10.1832H8.18105L9.02676 11.0596C9.32263 11.3662 9.32437 11.8643 9.02904 12.1703C8.73165 12.4785 8.25387 12.4753 7.95727 12.168L5.82058 9.95364C5.67227 9.79993 5.59868 9.59973 5.60002 9.39888C5.5999 9.19831 5.67329 8.99792 5.82286 8.84292L7.95727 6.63094C8.25314 6.32432 8.73371 6.32251 9.02904 6.62858ZM6.35556 15.3995C6.35556 14.9666 6.69111 14.6157 7.11089 14.6157H16.6243L15.7803 13.7393C15.4844 13.4319 15.4813 12.9368 15.7781 12.6286C16.0728 12.3225 16.5524 12.3243 16.8476 12.6309L18.9776 14.8429C19.1269 14.9979 19.2001 15.1983 19.2 15.3989C19.2013 15.5997 19.1279 15.7999 18.9799 15.9536L16.8476 18.168C16.5516 18.4753 16.0748 18.4785 15.7781 18.1703C15.4833 17.8643 15.4851 17.3662 15.7803 17.0596L16.6243 16.1832H7.11089C6.69373 16.1832 6.35556 15.8353 6.35556 15.3995Z'
        />
    </svg>
)
export const trash = (props) => (
    <svg
        {...props}
        width='20'
        height='24'
        viewBox='0 0 20 24'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.252 24C17.6287 24 18.75 22.8691 18.75 21.4741V5.05263C19.4448 5.05204 20 4.48673 20 3.78947C20 3.08699 19.4399 2.52632 18.7489 2.52632H12.5C12.5 1.13107 11.3807 0 10 0C8.61929 0 7.5 1.13107 7.5 2.52632H1.25109C0.555789 2.52632 0 3.09185 0 3.78947C0 4.49159 0.559543 5.05204 1.25 5.05263V21.4741C1.25 22.8715 2.3684 24 3.74802 24H16.252ZM16.252 21.4737C16.252 21.4737 3.75 21.4754 3.75 21.4741V5.05263H16.2499C16.2498 9.09851 16.2496 21.4737 16.252 21.4737ZM11.25 8.839C11.25 8.14309 11.8048 7.57895 12.5 7.57895C13.1904 7.57895 13.75 8.14271 13.75 8.839V17.6873C13.75 18.3832 13.1952 18.9474 12.5 18.9474C11.8096 18.9474 11.25 18.3836 11.25 17.6873V8.839ZM7.5 7.57895C6.80483 7.57895 6.25 8.14309 6.25 8.839V17.6873C6.25 18.3836 6.80964 18.9474 7.5 18.9474C8.19517 18.9474 8.75 18.3832 8.75 17.6873V8.839C8.75 8.14271 8.19036 7.57895 7.5 7.57895Z'
        />
    </svg>
)
export const check = (props) => (
    <svg
        {...props}
        width='20'
        height='14'
        viewBox='0 0 20 14'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.27218 13.4533L0.557892 7.85327C-0.185964 7.12429 -0.185964 5.94238 0.557892 5.2134C1.30175 4.48442 2.50778 4.48442 3.25163 5.2134L7.61905 9.51976L16.7484 0.546734C17.4922 -0.182245 18.6983 -0.182245 19.4421 0.546734C20.186 1.27571 20.186 2.45762 19.4421 3.1866L8.96592 13.4533C8.22206 14.1822 7.01603 14.1822 6.27218 13.4533Z'
        />
    </svg>
)
export const close = (props) => (
    <svg
        {...props}
        width='14'
        height='14'
        viewBox='0 0 14 14'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 4.63348L11.1459 0.486827C11.7974 -0.164843 12.8534 -0.161438 13.5067 0.49206C14.1647 1.15011 14.1624 2.20274 13.512 2.85335L9.36608 7L13.512 11.1466C14.1624 11.7973 14.1647 12.8499 13.5067 13.5079C12.8534 14.1614 11.7974 14.1648 11.1459 13.5132L7 9.36652L2.85412 13.5132C2.20257 14.1648 1.14665 14.1614 0.493273 13.5079C-0.164658 12.8499 -0.162445 11.7973 0.488041 11.1466L4.63392 7L0.488041 2.85335C-0.162445 2.20274 -0.164658 1.15011 0.493273 0.49206C1.14665 -0.161438 2.20257 -0.164843 2.85412 0.486827L7 4.63348Z'
        />
    </svg>
)

export const infoTooltip = (props) => (
    <svg
        {...props}
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM10 8.57143C10.3943 8.57143 10.7143 8.89143 10.7143 9.28571V15C10.7143 15.3943 10.3943 15.7143 10 15.7143C9.60571 15.7143 9.28571 15.3943 9.28571 15V9.28571C9.28571 8.89143 9.60571 8.57143 10 8.57143ZM10.7143 5C10.7143 4.60571 10.3943 4.28571 10 4.28571C9.60571 4.28571 9.28571 4.60571 9.28571 5V6.42857C9.28571 6.82286 9.60571 7.14286 10 7.14286C10.3943 7.14286 10.7143 6.82286 10.7143 6.42857V5Z'
        />
    </svg>
)

export const checkRadial = (props) => (
    <svg
        {...props}
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13ZM10.3905 17.6095L6.39052 13.6095C5.86983 13.0888 5.86983 12.2446 6.39052 11.7239C6.91122 11.2032 7.75544 11.2032 8.27614 11.7239L11.3333 14.7998L17.7239 8.39052C18.2446 7.86983 19.0888 7.86983 19.6095 8.39052C20.1302 8.91122 20.1302 9.75544 19.6095 10.2761L12.2761 17.6095C11.7554 18.1302 10.9112 18.1302 10.3905 17.6095Z'
            fill='white'
        />
    </svg>
)

export const noPreview = (props) => (
    <svg
        {...props}
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5 2C3.343 2 2 3.343 2 5V16.586L6.293 12.293C6.683 11.902 7.317 11.902 7.707 12.293L15.5 20.086L20.293 15.293C20.683 14.902 21.317 14.902 21.707 15.293L26 19.586V5C26 3.343 24.657 2 23 2H5ZM28 5C28 2.239 25.761 0 23 0H5C2.239 0 0 2.239 0 5V23C0 25.761 2.239 28 5 28H23C25.761 28 28 25.761 28 23V5ZM26 22.414L21 17.414L16.914 21.5L21.414 26H23C24.657 26 26 24.657 26 23V22.414ZM18.586 26L7 14.414L2 19.414V23C2 24.657 3.343 26 5 26H18.586ZM20 6C18.895 6 18 6.895 18 8C18 9.105 18.895 10 20 10C21.105 10 22 9.105 22 8C22 6.895 21.105 6 20 6ZM16 8C16 5.791 17.791 4 20 4C22.209 4 24 5.791 24 8C24 10.209 22.209 12 20 12C17.791 12 16 10.209 16 8Z'
            fill='#C4C4C4'
        />
    </svg>
)

export const closePopup = (props) => (
    <svg
        {...props}
        width='12'
        height='12'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.386985 0.387041C0.901645 -0.129014 1.73698 -0.129014 2.25296 0.387041L8 6.13491L13.747 0.387041C14.263 -0.129014 15.0984 -0.129014 15.613 0.387041C16.129 0.901776 16.129 1.73855 15.613 2.25328L9.86597 8.00115L15.613 13.753C16.129 14.2677 16.129 15.0992 15.613 15.6139C15.0984 16.1287 14.263 16.1287 13.747 15.6139L8 9.8674L2.25296 15.6139C1.73698 16.1287 0.901645 16.1287 0.386985 15.6139C-0.128995 15.0992 -0.128995 14.2677 0.386985 13.753L6.13403 8.00115L0.386985 2.25328C-0.128995 1.73855 -0.128995 0.901776 0.386985 0.387041Z'
            fill='black'
        />
    </svg>
)
export const LogoReturnado = (props) => (
    <svg id="Layer_1"

         {...props}

         data-name="Layer 1"

         xmlns="http://www.w3.org/2000/svg"

         viewBox="0 0 632.83 167.94">

        <path d="M27.51,103.71a28.05,28.05,0,0,1,56.1,0V167h27.51v-63.3A55.56,55.56,0,0,0,0,103.71v1.1H27.52Z"/>

        <path d="M0,163v4H27.52v-4Z"/>

        <path d="M0,144.94v8H27.52v-8Z"/>

        <path d="M0,126.88v10H27.52v-10Z"/>

        <path d="M0,108.82v12H27.52v-12Z"/>

        <polygon points="466.45 55.65 478.06 83.17 522.37 83.17 522.37 55.65 466.45 55.65"/>

        <polyline points="450.55 55.56 450.55 167.01 478.06 167.01 478.06 55.56"/>

        <path d="M478.06,55.56a28,28,0,0,1,28.05-28h16.26V0H506.11a55.57,55.57,0,0,0-55.57,55.56"/>

        <path d="M293.94,103.71a28.05,28.05,0,0,1,56.1,0V167h27.51v-63.3a55.56,55.56,0,1,0-111.12,0v5.89H294Z"/><rect x="400.29" width="27.51" height="27.51"/><polygon points="427.81 55.61 425.91 55.61 402.19 55.61 400.29 55.61 400.29 57.43 400.29 165.19 400.29 167.01 402.19 167.01 425.91 167.01 427.81 167.01 427.81 165.19 427.81 57.43 427.81 55.61"/><rect x="266.43" width="27.51" height="167.01"/><path d="M229.94,87.26c-8.28-7-17.89-10.36-26.36-13.32l-1.83-.64c-3.16-1.11-6.45-2.17-9.09-3-9.36-3-18.2-5.89-25.22-10.8-4.32-3-5.64-5.58-5.64-10.92,0-5.88,2.06-10.63,6.13-14.1,4.53-3.89,11.28-6,19-6,7.3,0,13.61,1.85,18.75,5.49a30.15,30.15,0,0,1,8.18,8.83l1,1.57,11.86-6.85,11.94-6.9-.58-1-.41-.69-.91-1.46a58,58,0,0,0-15.16-16C211.67,4.47,200,.92,186.91.92,172.65.92,159.56,5.41,150,13.55c-10.3,8.82-15.74,20.92-15.74,35a40.94,40.94,0,0,0,4.31,19A40.69,40.69,0,0,0,151.67,82c10.39,7.27,21.69,10.93,32.63,14.47,2.89.94,5.62,1.82,8.33,2.77l1.88.66c6.83,2.39,13.27,4.64,17.67,8.36,3.81,3.22,5.91,7.34,5.91,11.6,0,6-2.08,10.81-6.17,14.3-4.83,4.11-12.4,6.28-21.89,6.28-9.76,0-17.84-2.21-24-6.55a28.64,28.64,0,0,1-9-10.45l-.84-1.63-24.79,11.88.87,1.72a56.78,56.78,0,0,0,18,21c10.9,7.67,24.31,11.57,39.85,11.57,16.11,0,29.84-4.44,39.71-12.84,10.37-8.82,15.85-21,15.85-35.24C245.59,107.56,239.88,95.67,229.94,87.26Z"/><polyline points="545.45 111.45 545.45 0 572.97 0 572.97 111.45"/><rect x="566.59" y="55.65" width="14.46" height="27.52"/>

        <path d="M573,111.45a28,28,0,0,0,28,28.05h31.82V167H601a55.56,55.56,0,0,1-55.56-55.56"/><rect x="629.26" y="55.65" width="3.57" height="27.52"/><rect x="613.19" y="55.65" width="7.14" height="27.52"/><polyline points="606.05 83.17 606.05 55.65 597.12 55.65 597.12 83.17"/>

        <path d="M581.06,83.17h10.71V55.65H581.06Z"/>
    </svg>
)
export const LogoMerchant = (props) => (
    <svg
        {...props}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <mask
            id='mask0'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='48'
            height='48'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 0H48V48H0V0Z'
                fill='white'
            />
        </mask>
        <g mask='url(#mask0)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.0629 26.8123C20.0629 27.3302 19.6431 27.75 19.1252 27.75C18.6073 27.75 18.1875 27.3302 18.1875 26.8123C18.1875 26.2944 18.6073 25.8746 19.1252 25.8746C19.6431 25.8746 20.0629 26.2944 20.0629 26.8123ZM24.3716 26.8123C24.3716 27.3302 23.9518 27.75 23.4339 27.75C22.9161 27.75 22.4963 27.3302 22.4963 26.8123C22.4963 26.2944 22.9161 25.8746 23.4339 25.8746C23.9518 25.8746 24.3716 26.2944 24.3716 26.8123ZM34.125 31.875C34.125 32.3929 33.7054 32.8125 33.1875 32.8125C32.6698 32.8125 32.25 32.3929 32.25 31.875V18.3356C32.25 17.7103 31.9204 17.1617 31.3686 16.8679C30.8166 16.5742 30.1772 16.6074 29.6587 16.9566L26.6387 18.9906C25.0357 20.0702 22.9643 20.07 21.3615 18.9906L18.3412 16.9566C17.8228 16.6074 17.1838 16.5741 16.6314 16.8679C16.0796 17.1617 15.75 17.7103 15.75 18.3356V27.5331C15.75 29.4739 17.1203 30.9375 18.9375 30.9375H24.9375C25.4554 30.9375 25.875 31.3571 25.875 31.875C25.875 32.3929 25.4554 32.8125 24.9375 32.8125H18.9375C16.0986 32.8125 13.875 30.4935 13.875 27.5331V18.3356C13.875 17.025 14.5937 15.8284 15.7507 15.2126C16.9074 14.5969 18.3013 14.6691 19.3886 15.4014L22.4087 17.4354C23.3754 18.0863 24.6246 18.0863 25.5913 17.4352L28.6114 15.4014C29.6983 14.6694 31.0924 14.5967 32.2493 15.2126C33.4063 15.8284 34.125 17.025 34.125 18.3356V31.875ZM24 0C10.7451 0 0 10.7452 0 24C0 37.2547 10.7451 48 24 48C37.2549 48 48 37.2547 48 24C48 10.7452 37.2549 0 24 0Z'
                fill='#FF5894'
            />
        </g>
    </svg>
)

export const RefundSeparator = (props) => (
    <svg
        {...props}
        width='124'
        height='20'
        viewBox='0 0 124 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect y='9' width='40' height='1' fill='#DCDCDC'/>
        <rect x='84' y='9' width='40' height='1' fill='#DCDCDC'/>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M72 10C72 15.5228 67.5228 20 62 20C56.4772 20 52 15.5228 52 10C52 4.47715 56.4772 0 62 0C67.5228 0 72 4.47715 72 10ZM59.2115 5.19048C59.4575 5.44731 59.455 5.85994 59.2096 6.11608L58.5101 6.84645H66.3742C66.7199 6.84645 67 7.13885 67 7.49955C67 7.86276 66.7198 8.15265 66.3742 8.15265H58.5101L59.2096 8.88302C59.4544 9.13854 59.4558 9.55356 59.2115 9.80862C58.9655 10.0654 58.5703 10.0628 58.325 9.80665L56.5575 7.96136C56.4348 7.83328 56.3739 7.66644 56.375 7.49907C56.3749 7.33192 56.4356 7.16493 56.5594 7.03577L58.325 5.19245C58.5697 4.93693 58.9672 4.93543 59.2115 5.19048ZM57 12.4995C57 12.1389 57.2776 11.8464 57.6248 11.8464H65.4944L64.7962 11.1161C64.5514 10.8599 64.5489 10.4473 64.7944 10.1905C65.0382 9.93543 65.4349 9.93693 65.6791 10.1925L67.441 12.0358C67.5645 12.1649 67.6251 12.3319 67.625 12.4991C67.6261 12.6664 67.5653 12.8333 67.4429 12.9614L65.6791 14.8066C65.4343 15.0628 65.0399 15.0654 64.7944 14.8086C64.5506 14.5536 64.552 14.1385 64.7962 13.883L65.4944 13.1527H57.6248C57.2797 13.1527 57 12.8628 57 12.4995Z'
            fill='#DCDCDC'
        />
    </svg>
)

export const logout = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        fill="#000000">
        <path
            d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/>
    </svg>
)

export const email = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
    </svg>
)

export const googleIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
            <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
            <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
            <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
            <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
        </g>
    </svg>
)

export const facebookIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path
            d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
    </svg>
)


export const woodWoodLogo = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        fill={'#000'}
        viewBox="0 0 2080 270"
    >
        <g>
            <path
                d="M40,34.3h35.8l39.5,151.5l44.2-151.5h31.1l44.2,151.5l39.5-151.5h35.8l-55.5,201.4h-35.8L174.6,85.1l-44.2,150.6H95.5   L40,34.3z"/>
            <path
                d="M325.2,135c0-59.3,45.2-103.5,104.5-103.5S534.1,75.7,534.1,135s-45.2,103.5-104.5,103.5S325.2,195.2,325.2,135z    M498.4,135c0-41.4-29.2-70.6-69.6-70.6S360,93.6,360,135c0,40.5,29.2,70.6,69.6,70.6C469.2,205.6,498.4,175.5,498.4,135z"/>
            <path
                d="M559.5,135c0-59.3,45.2-103.5,104.5-103.5c59.3,0,104.5,44.2,104.5,103.5S723.3,238.5,664,238.5   C604.7,238.5,559.5,195.2,559.5,135z M732.7,135c0-41.4-29.2-70.6-69.6-70.6s-69.6,29.2-69.6,70.6c0,40.5,29.2,70.6,69.6,70.6   S732.7,175.5,732.7,135z"/>
            <path
                d="M805.2,34.3h67.8c55.5,0,107.3,30.1,107.3,100.7s-52.7,100.7-106.4,100.7h-68.7C805.2,235.7,805.2,34.3,805.2,34.3z    M874.8,204.6c38.6,0,69.6-24.5,69.6-69.6s-30.1-69.6-68.7-69.6h-37.6v139.3H874.8z"/>
            <path
                d="M1099.8,34.3h35.8l39.5,151.5l44.2-151.5h32l44.2,151.5l39.5-151.5h35.8l-55.5,201.4h-35.8l-44.2-150.6l-44.2,150.6h-35.8   L1099.8,34.3z"/>
            <path
                d="M1385.9,135c0-59.3,45.2-103.5,104.5-103.5c59.3,0,104.5,44.2,104.5,103.5s-45.2,103.5-104.5,103.5   C1430.1,238.5,1385.9,195.2,1385.9,135z M1559.1,135c0-41.4-29.2-70.6-69.6-70.6c-39.5,0-69.6,29.2-69.6,70.6   c0,40.5,29.2,70.6,69.6,70.6C1528.9,205.6,1559.1,175.5,1559.1,135z"/>
            <path
                d="M1620.2,135c0-59.3,45.2-103.5,104.5-103.5c59.3,0,104.5,44.2,104.5,103.5s-45.2,103.5-104.5,103.5   C1664.5,238.5,1620.2,195.2,1620.2,135z M1793.4,135c0-41.4-29.2-70.6-69.6-70.6s-69.6,29.2-69.6,70.6c0,40.5,29.2,70.6,69.6,70.6   C1763.3,205.6,1793.4,175.5,1793.4,135z"/>
            <path
                d="M1864.9,34.3h67.8c55.5,0,107.3,30.1,107.3,100.7s-52.7,100.7-106.4,100.7h-68.7L1864.9,34.3L1864.9,34.3z M1935.5,204.6   c38.6,0,69.6-24.5,69.6-69.6s-30.1-69.6-68.7-69.6h-37.6v139.3H1935.5z"/>
        </g>
    </svg>
)

export const nShiftLogo = (props) => (
    <svg 
        {...props}
        width="100%"
        height="32"
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1884.12 500"
    >
        <path 
            d="M165.43,143.35A165.42,165.42,0,0,0,0,308.78v3.28H81.92v-3.28a83.51,83.51,0,1,1,167,0V497.25h81.92V308.78A165.42,165.42,0,0,0,165.43,143.35Z"/>
        <path 
            d="M0,497.25H81.92V485.3H0Z"/>
        <path 
            d="M0,455.43H81.92V431.54H0Z"/>
        <path 
            d="M0,407.64H81.92V377.77H0Z"/>
        <path 
            d="M0,359.85H81.92V324H0Z"/>
        <path 
            d="M1341.41,165.43V497.25h81.92V247.62h131.93V165.7H1423.33v-.27a83.51,83.51,0,0,1,83.51-83.51h48.42V0h-48.42A165.42,165.42,0,0,0,1341.41,165.43Z"/>
        <path 
            d="M958.67,143.35A164.71,164.71,0,0,0,875.16,166V0H793.25V308.16c0,.21,0,.41,0,.62v17.54h0V497.25h81.91V308.6a83.51,83.51,0,0,1,167,.18V497.25h81.92V308.78A165.42,165.42,0,0,0,958.67,143.35Z"/>
        <rect 
            x="1191.79" width="81.92" height="81.92"/>
        <polygon 
            points="1273.71 165.56 1268.06 165.56 1197.43 165.56 1191.79 165.56 1191.79 170.98 1191.79 491.83 1191.79 497.25 1197.43 497.25 1268.07 497.25 1273.71 497.25 1273.71 491.83 1273.71 170.98 1273.71 165.56"/>
        <path 
            d="M684.59,259.81c-24.65-20.87-53.24-30.86-78.46-39.67l-5.46-1.91c-9.41-3.31-19.19-6.47-27-9-27.88-9-54.21-17.54-75.09-32.16-12.88-9-16.81-16.62-16.81-32.52,0-17.5,6.14-31.63,18.25-42C513.48,91,533.55,84.63,556.5,84.63c21.74,0,40.52,5.49,55.83,16.32a90.39,90.39,0,0,1,24.34,26.3l2.88,4.67,35.32-20.39L710.41,91l-1.72-2.87-1.23-2q-1.32-2.19-2.7-4.34a172.5,172.5,0,0,0-45.14-47.63C630.21,13.3,595.52,2.75,556.5,2.75c-42.46,0-81.44,13.35-109.77,37.6-30.67,26.24-46.88,62.27-46.88,104.19,0,21.16,4.32,40.21,12.85,56.63s21.6,30.88,38.86,43c30.93,21.66,64.59,32.55,97.16,43.09,8.6,2.79,16.73,5.41,24.79,8.26l5.62,2c20.31,7.1,39.49,13.79,52.59,24.88,11.35,9.6,17.6,21.87,17.6,34.55,0,17.87-6.18,32.18-18.37,42.56-14.38,12.23-36.91,18.7-65.17,18.7-29.06,0-53.11-6.57-71.48-19.51a85.4,85.4,0,0,1-26.9-31.09l-2.51-4.88L391.1,398l2.58,5.14a169.17,169.17,0,0,0,53.46,62.39C479.58,488.41,519.5,500,565.78,500c48,0,88.85-13.22,118.24-38.22,30.86-26.27,47.18-62.55,47.18-104.91C731.2,320.23,714.21,284.85,684.59,259.81Z"/>
        <path 
            d="M1789.4,415.33a83.5,83.5,0,0,1-83.5-83.51v-84.2h56V165.7h-56V0H1624V331.82A165.43,165.43,0,0,0,1789.4,497.25h94.72V415.33Z"/>
        <rect 
            x="1873.49" y="165.7" width="10.63" height="81.92"/>
        <rect 
            x="1825.66" y="165.7" width="21.26" height="81.92"/>
        <rect 
            x="1777.82" y="165.7" width="26.58" height="81.92"/>
    </svg>
)
