import styled, { css } from 'styled-components'
import { rem } from '../../../utils/common'
import { fonts } from '../../Variable'
import { device } from '../../Variable'

export const InputWrapperStyle = styled.label`
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	width: 100%;
`
export const Error = styled.div`
	padding-top: ${rem(3)};
	font-size: ${rem(12)};
	line-height: 1;
	color: ${({ theme }) => theme.color.redOne};
	${(p) => p.isMain && css`
		position: absolute;
		white-space: nowrap;
		@media (${device.mobileL}) {
			top: ${rem(44)};
			left: ${rem(-4)};
		}
		@media (${device.mobileS}) {
			left: ${rem(-65)};
		}
	`}
	${(p) => p.isCustomized && css`
		@media (${device.mobileM}) {
			position: absolute;
			top: ${rem(48)};
		}
	`}
`
export const InputStyle = styled.input`
	display: block;
	width: 100%;
	min-width: 0;
	flex: 0 0 100%;
	max-width: 100%;
	padding:${rem(0, 22)};
	height: ${rem(50)};
	background-color: transparent;
	font-family: ${fonts.Nunito};
	font-size: ${rem(16)};
	line-height: ${rem(18)};
	border-radius: ${rem(5)};
	border: ${rem(1)} solid ${({theme}) => theme.color.grayOne};
	transition: all 0.3s;

	${(p) => p.error && css`
		border: 1px solid ${({ theme }) => theme.color.redOne};
	`}

	&::placeholder {
		font-size: ${rem(16)};
		line-height: ${rem(48)};
		
		${(p) =>
			p.placeholderNotBold &&
			css`
				font-weight: 400;
			`}
	}

	&::-webkit-input-placeholder {
		line-height: normal;
	}

	@media (${device.mobileXL}) {
		padding:${rem(0, 16)};

	}
}
`
